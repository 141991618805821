import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Assets from '@components/about-etno-cafe/our-history/years/assets';

const Wrapper = tw.div`relative mt-30 px-4 md:px-0 lg:flex lg:items-center lg:mt-0`;

const AssetsMobile = tw(Assets)`mt-12 mb-30 lg:hidden`;

const AssetsDesktop = tw(Assets)`hidden lg:block`;

const ContentWrapper = tw.div`lg:w-345 lg:mt-16 lg:mr-85`;

const Subtitle = tw(Text)`lg:mb-24`;

const Number = tw(Text)`hidden lg:block`;

const Content = styled(Text)`
  ${tw`lg:-mr-20 xl:-mr-10`}

  b { ${tw`font-600`} }
`;

const Year = ({ year }) => (
  <Wrapper>
    <ContentWrapper>
      <Number fontFamily="bebasNeue" color="pink" fontSizes={28} lineHeight={1.14} letterSpacing={35}>
        <FormattedMessage id={`about etno cafe.our history.story.${year}.year`} />
      </Number>
      <Text fontFamily="bebasNeue" fontWeight={700} fontSizes={28} lineHeight={1.14} letterSpacing={35}>
        <FormattedMessage id={`about etno cafe.our history.story.${year}.title`} />
      </Text>
      <Subtitle fontFamily="bebasNeue" fontWeight={300} fontSizes={28} lineHeight={1.14} letterSpacing={35}>
        <FormattedMessage id={`about etno cafe.our history.story.${year}.subtitle`} />
      </Subtitle>
      <AssetsMobile year={year} />
      <Content fontSizes={16} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id={`about etno cafe.our history.story.${year}.content`} />
      </Content>
    </ContentWrapper>
    <AssetsDesktop year={year} />
  </Wrapper>
);

Year.propTypes = {
  year: PropTypes.number.isRequired,
};

export default Year;
