import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import LinkDefault from '@components/link';

const Link = tw(LinkDefault)`relative self-center inline-block mt-30`;

const Button = tw(ButtonDefault)`mt-0 py-10 px-30`;

const CTA = () => (
  <Link to="/job">
    <Button>
      <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
        <FormattedMessage id="navigation.check job offers" />
      </Text>
    </Button>
  </Link>
);

export default CTA;
