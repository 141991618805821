import React from 'react';
import { array } from '@utils/helpers';
import tw from 'twin.macro';

import Item from '@components/about-etno-cafe/our-history/concepts/item';

const Wrapper = tw.div`hidden md:flex md:justify-center`;

const WrapperItem = tw.div`w-200 mx-60 first:mx-0 last:mx-0 lg:w-240 lg:mx-120`;

const Columns = () => (
  <Wrapper>
    {array(3).map((number) => (
      <WrapperItem key={number}>
        <Item number={number} />
      </WrapperItem>
    ))}
  </Wrapper>
);

export default Columns;
