import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`mb-18 lg:mb-50`;

const Title = styled(Text)`
  ${tw`my-20 md:whitespace-normal lg:mt-10 lg:mb-24`}
  
  br { ${tw`hidden md:block`} }
`;

const Subheader = styled(Text)`
  ${tw`md:w-650 md:mx-auto`}

  b { ${tw`font-600`} }
`;

const Header = () => (
  <Wrapper>
    <Text color="blue" fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
      <FormattedMessage id="about etno cafe.our history.hashtag" />
    </Text>
    <Title
      fontFamily="bebasNeue"
      fontSizes={[42, 54]}
      fontWeight={300}
      lineHeight={[1.19, 1.15]}
      letterSpacing={[52, 67]}
    >
      <FormattedHTMLMessage id="about etno cafe.our history.header" />
    </Title>
    <Subheader fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedHTMLMessage id="about etno cafe.our history.subheader" />
    </Subheader>
  </Wrapper>
);

export default Header;
