import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Title = tw(Text)`mt-15 mb-35 lg:mt-10 lg:mb-46`;

const Header = () => (
  <>
    <Text color="blue" fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
      <FormattedMessage id="about etno cafe.how we work.hashtag" />
    </Text>
    <Title
      fontFamily="bebasNeue"
      fontSizes={[42, 54]}
      fontWeight={700}
      lineHeight={[1.19, 1.15]}
      letterSpacing={[52, 67]}
    >
      <FormattedMessage id="about etno cafe.how we work.title" />
    </Title>
  </>
);

export default Header;
