import React from 'react';
import tw, { styled } from 'twin.macro';
import { array } from '@utils/helpers';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';

import Text from '@components/text';
import List from '@components/about-etno-cafe/values/list';

const values = [
  { number: 1, list: false },
  { number: 2, list: false },
  { number: 3, list: array(4) },
];

const ItemWrapper = tw.div`
  mb-38 pb-38 border-b border-backgroundBlue last:pb-0 last:border-0
  md:flex md:ml-95 md:mb-38 md:pb-56
  lg:ml-106
`;

const Description = styled.div`
  ${ItemWrapper}:nth-of-type(1) & {
    @media (min-width: 768px) {
      margin-left: ${({ lang }) => (lang === 'en' ? 110 : 100)}px;
    }

    @media (min-width: 1024px) {
      margin-left: ${({ lang }) => (lang === 'en' ? 174 : 142)}px;
    }
  }

  ${ItemWrapper}:nth-of-type(2) & {
    @media (min-width: 768px) {
      margin-left: ${({ lang }) => (lang === 'en' ? 38 : 79)}px;
    }

    @media (min-width: 1024px) {
      margin-left: ${({ lang }) => (lang === 'en' ? 88 : 116)}px;
    }
  }

  ${ItemWrapper}:nth-of-type(3) & {
    @media (min-width: 768px) {
      margin-left: ${({ lang }) => (lang === 'en' ? 44 : 61)}px;
    }

    @media (min-width: 1024px) {
      margin-left: ${({ lang }) => (lang === 'en' ? 95 : 95)}px;
    }
  }
`;

const DescriptionItem = styled(Text)`
  ${tw`mt-24 md:mt-0 `}

  b { ${tw`font-600`} }
`;

const Items = () => {
  const { locale } = useIntl();

  return (
    <div>
      {values.map(({ number, list }) => (
        <ItemWrapper key={number}>
          <Text fontFamily="bebasNeue" fontSizes={[40, 48]} fontWeight={700} lineHeight={1} letterSpacing={52}>
            <FormattedMessage id={`about etno cafe.values.types.${number}.title`} />
          </Text>
          <Description lang={locale}>
            <DescriptionItem fontSizes={16} lineHeight={1.5} letterSpacing={20}>
              <FormattedHTMLMessage id={`about etno cafe.values.types.${number}.description`} />
            </DescriptionItem>
            {list && (
              <List index={number} item={list} lang={locale} />
            )}
          </Description>
        </ItemWrapper>
      ))}
    </div>
  );
};

export default Items;
