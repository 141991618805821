import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Masks from '@components/about-etno-cafe/our-history/masks';
import Header from '@components/about-etno-cafe/our-history/header';
import Concepts from '@components/about-etno-cafe/our-history/concepts';
import Images from '@components/about-etno-cafe/our-history/images';
import Years from '@components/about-etno-cafe/our-history/years';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 mb-40 pt-80 pb-45 text-center
    sm:w-460 sm:pt-100
    md:w-auto
    lg:mb-56 lg:pt-110 lg:pb-80
  `}
`;

const OurHistory = () => (
  <>
    <Wrapper id="ourHistory">
      <Masks />
      <Images />
      <Header />
      <Concepts />
    </Wrapper>
    <Years />
  </>
);

export default OurHistory;
