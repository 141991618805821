import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Title = tw(Text)`my-15 lg:my-10`;

const ContentWrapper = tw.div`md:flex md:justify-between`;

const TextContent = tw(Text)`md:w-340 lg:w-460 xl:w-535`;

const ContentExtra = styled(TextContent)`
  ${tw`mt-15 md:mt-0`}
  
  span span { ${tw`underline`} }
`;

const Content = () => (
  <>
    <Text fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
      <FormattedMessage id="about etno cafe.careers.hashtag" />
    </Text>
    <Title fontFamily="bebasNeue" fontSizes={[42, 54]} fontWeight={300} lineHeight={[1.25, 1]} letterSpacing={[107, 138]}>
      <FormattedHTMLMessage id="about etno cafe.careers.title" />
    </Title>
    <ContentWrapper>
      <TextContent fontSizes={16} lineHeight={1.5} letterSpacing={20}>
        <FormattedMessage id="about etno cafe.careers.content" />
      </TextContent>
      <ContentExtra fontSizes={16} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id="about etno cafe.careers.extra content" />
      </ContentExtra>
    </ContentWrapper>
  </>
);

export default Content;
