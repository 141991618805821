import React, { useState } from 'react';
import { array } from '@utils/helpers';
import tw, { styled } from 'twin.macro';
import SwipeableViews from 'react-swipeable-views';

import Item from '@components/about-etno-cafe/our-history/concepts/item';

const Wrapper = tw.div`md:hidden`;

const SwipeableViewsWrapper = styled.div`
  ${tw`relative transition-all duration-300`}
  
  & > div { ${tw`overflow-visible! pr-50`} }
  
  .react-swipeable-view-container > div {
    ${tw`px-10 transition-all duration-300`}
  }
`;

const Pagination = tw.div`flex justify-center items-center mt-25`;

const DotWrapper = tw.div`flex justify-center items-center w-20 h-12`;

const Dot = styled.div`
  ${tw`w-10 h-10 bg-brownDot opacity-50 rounded-full transition-all duration-300`}
  ${({ active }) => active && tw`opacity-100`}
`;

const Slider = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleClickedSlide = (e, index) => {
    e.preventDefault();
    setActiveSlide(index);
  };

  return (
    <Wrapper>
      <SwipeableViewsWrapper>
        <SwipeableViews
          index={activeSlide}
          onChangeIndex={setActiveSlide}
        >
          {array(3).map((number, index) => (
            <Item
              key={number}
              number={number}
              onClick={(e) => activeSlide !== index && handleClickedSlide(e, index)}
            />
          ))}
        </SwipeableViews>
      </SwipeableViewsWrapper>
      <Pagination>
        {array(3).map((number, index) => (
          <DotWrapper
            key={`${number}id`}
            onClick={() => activeSlide !== index && setActiveSlide(index)}
          >
            <Dot active={activeSlide === index} />
          </DotWrapper>
        ))}
      </Pagination>
    </Wrapper>
  );
};

export default Slider;
