import React from 'react';

import Slider from '@components/about-etno-cafe/our-history/concepts/slider';
import Columns from '@components/about-etno-cafe/our-history/concepts/columns';

const Concepts = () => (
  <>
    <Slider />
    <Columns />
  </>
);

export default Concepts;
