import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Header from '@components/about-etno-cafe/values/header';
import Items from '@components/about-etno-cafe/values/items';
import Triangles from '@components/about-etno-cafe/values/triangles';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 pt-52 px-10 text-center
    sm:w-460
    md:w-auto md:pt-50 md:text-left
    lg:px-0
  `}
`;

const Values = () => (
  <Wrapper id="values">
    <Triangles />
    <Header />
    <Items />
  </Wrapper>
);

export default Values;
