import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`mb-72 lg:mb-86`;

const Title = tw(Text)`mt-8 mb-15 md:mt-14 md:mb-0`;

const Header = () => (
  <Wrapper>
    <Text color="white" fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
      <FormattedMessage id="about etno cafe.values.hashtag" />
    </Text>
    <Title
      fontFamily="bebasNeue"
      fontSizes={[42, 54]}
      fontWeight={300}
      lineHeight={[1.19, 1.4]}
      letterSpacing={[107, 138]}
    >
      <FormattedHTMLMessage id="about etno cafe.values.title" />
    </Title>
    <Text fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedMessage id="about etno cafe.values.subtitle" />
    </Text>
  </Wrapper>
);

export default Header;
