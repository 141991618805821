import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const Oval = styled.div`
  right: 2px;
  width: 418px;
  height: 418px;
  ${tw`absolute top-0 bg-pink rounded-full -z-1`}
  
  @media (min-width: 640px) {
    right: 85px;
  }
  
  @media (min-width: 768px) {
    top: 20px;
    left: -100px;
    right: initial;
  }
  
  @media (min-width: 1024px) {
    top: 143px;
    left: -102px;
  }
`;

const Main = styled(Img)`
  top: -30px;
  right: 20px;
  ${tw`hidden absolute! w-500 -z-1 md:block lg:w-800`}
  
  @media (min-width: 1024px) {
    top: -20px;
    right: 32px;
  }
  
  @media (min-width: 1280px) {
    width: 878px;
  }
`;

const Beans = styled(Img)`
  bottom: -35px;
  right: -10px;
  ${tw`absolute! w-82`}
  
  @media (min-width: 768px) {
    bottom: -20px;
    left: 280px;
    right: initial;
  }
  
  @media (min-width: 1024px) {
    bottom: -35px;
    top: 74px;
    bottom: initial;
    left: -50px;
  }
  
  @media (min-width: 1280px) {
    left: -114px;
  }
`;

const BeansOne = styled(Img)`
  top: 10px;
  left: 15px;
  ${tw`absolute! w-35 lg:w-45`}

  @media (min-width: 640px) {
    left: -35px;
  }
  
  @media (min-width: 1024px) {
    top: initial;
    bottom: 80px;
    left: 350px;
    transform: rotate(-110deg) scaleY(-1);
  }
  
  @media (min-width: 1280px) {
    bottom: 150px;
  }
`;

const Images = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "about/header.png" }) {
        childImageSharp {
          fluid(maxWidth: 940) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      beans: file(relativePath: { eq: "coffee-beans-small.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      beansOne: file(relativePath: { eq: "coffee-beans-one.png" }) {
        childImageSharp {
          fluid(maxWidth: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Oval />
      <Main fluid={data.main.childImageSharp.fluid} />
      <Beans fluid={data.beans.childImageSharp.fluid} />
      <BeansOne fluid={data.beansOne.childImageSharp.fluid} />
    </>
  );
};

export default Images;
