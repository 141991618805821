import React from 'react';
import tw, { styled } from 'twin.macro';

const Element = styled.div`
  top: -80px;
  right: -290px;
  border-top: 800px solid #D7EFFD;
  border-left: 600px solid transparent;
  border-right: 600px solid transparent;
  transform: rotate(-90deg);
  ${tw`absolute w-0 h-0 -z-1`}

  @media (min-width: 640px) {
    top: 70px;
    right: 50px;
    border-top-width: 600px;
    border-left-width: 400px;
    border-right-width: 400px;
  }
  
  @media (min-width: 768px) {
    top: 120px;
    right: 270px;
    border-top-width: 450px;
    border-left-width: 400px;
    border-right-width: 400px;
  }

  @media (min-width: 1024px) {
    top: -230px;
    right: 610px;
    border-top-width: 820px;
    border-left-width: 380px;
    border-right-width: 630px;
  }

  @media (min-width: 1921px) {
    top: -280px;
    right: 890px;
    border-top-width: 1000px;
    border-left-width: 300px;
    border-right-width: 650px;
  }
`;

const Triangle = () => <Element />;

export default Triangle;
