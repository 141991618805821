import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import CTA from '@components/about-etno-cafe/header/cta';

const Hashtags = tw(Text)`-mx-20 sm:mx-0`;

const Title = tw(Text)`mt-16 lg:mb-15`;

const Content = () => (
  <>
    <Hashtags color="redDark" fontSizes={14} lineHeight={1.4} letterSpacing={18}>
      <FormattedMessage id="about etno cafe.header.hashtags" />
    </Hashtags>
    <Title fontFamily="bebasNeue" fontSizes={54} fontWeight={300} lineHeight={1} letterSpacing={138}>
      <FormattedHTMLMessage id="about etno cafe.header.title" />
    </Title>
    <Text fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedMessage id="about etno cafe.header.subtitle" />
    </Text>
    <CTA />
  </>
);

export default Content;
