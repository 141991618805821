import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled, css } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ShapeDefault from '@images/about/nav.svg';

const Wrapper = styled.div`
  top: 165px;
  left: -120px;
  ${tw`hidden absolute text-right lg:block`}

  @media (min-width: 1140px) { left: -180px; }

  @media (min-width: 1281px) { left: -200px; }

  @media (min-width: 1441px) { left: -410px; }

  @media (min-width: 1921px) { left: -730px; }
`;

const NavItem = styled(Text)`
  ${tw`relative w-200 pt-34 pr-50 pb-10 cursor-pointer`}
  
  :nth-of-type(1) { top: 0; z-index: 4; ${tw`py-24`} }
  :nth-of-type(2) { top: -20px; z-index: 3; }
  :nth-of-type(3) { top: -40px; z-index: 2; }
  :nth-of-type(4) { top: -60px; z-index: 1; }

  @media (min-width: 1441px) {
    width: 410px;
    padding-right: 55px;
  }

  @media (min-width: 1921px) {
    width: 730px;
    padding-right: 70px;
  }
`;

const Shape = styled(ShapeDefault)`
  top: 0;
  right: 28px;
  ${tw`absolute w-full h-full transition-all duration-400 -z-1`}

  ${NavItem}:nth-of-type(2) & .color { fill: #F7D8D1; }
  ${NavItem}:nth-of-type(3) & .color { fill: #FAE6E2; }
  ${NavItem}:nth-of-type(4) & .color { fill: #FAE9E5; opacity: 0.78; }

  ${({ firstelem, lastelem }) => (firstelem || lastelem) && css`right: 36px`}
  ${({ halfactive }) => halfactive && css`right: 20px`}
  ${({ active }) => active && css`right: 10px;`}
  
  @media (min-width: 1280px) {
    right: 24px;
  
    ${({ firstelem, lastelem }) => (firstelem || lastelem) && css`right: 36px`}
    ${({ halfactive }) => halfactive && css`right: 12px`}
    ${({ active }) => active && css`right: 0;`}
  }
`;

const MenuDesktop = ({ years, activeYear, handleYear }) => (
  <Wrapper>
    {years.map(({ year, halfActive }) => (
      <NavItem
        key={year}
        fontFamily="bebasNeue"
        fontSizes={24}
        fontWeight={700}
        letterSpacing={30}
        onClick={() => handleYear(year)}
      >
        <Shape
          active={year === activeYear}
          halfactive={halfActive.find((number) => number === activeYear)}
          firstelem={activeYear === 2012 && year === 2021}
          lastelem={activeYear === 2021 && year === 2012}
        />
        <FormattedMessage id={`about etno cafe.our history.story.${year}.year`} />
      </NavItem>
    ))}
  </Wrapper>
);

MenuDesktop.propTypes = {
  years: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
    ])),
  ).isRequired,
  activeYear: PropTypes.number.isRequired,
  handleYear: PropTypes.func.isRequired,
};

export default MenuDesktop;
