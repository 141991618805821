import React from 'react';
import { array } from '@utils/helpers';
import tw from 'twin.macro';

import Column from '@components/about-etno-cafe/how-we-work/column';

const columns = [
  { list: array(4) },
  { list: array(5) },
  { list: false },
];

const Wrapper = tw.div`md:flex md:justify-between`;

const Columns = () => (
  <Wrapper>
    {columns.map(({ list }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Column key={index} number={index + 1} list={list} />
    ))}
  </Wrapper>
);

export default Columns;
