import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const Concrete = styled(Img)`
  left: -160px;
  transform: scaleX(-1);
  ${tw`absolute! top-0 w-540 -z-1 md:w-440 lg:w-780`}
  
  @media (min-width: 768px) { left: -140px; }
  
  @media (min-width: 1024px) { left: -410px; }
  
  @media (min-width: 1921px) { ${tw`hidden`} }
`;

const ConcreteBig = styled(Img)`
  left: -730px;
  transform: scaleX(-1);
  ${tw`hidden absolute! top-0 w-1100 -z-1`}
  
  @media (min-width: 1921px) { ${tw`block`} }
`;

const TriangleBlue = styled.div`
  top: 195px;
  right: -130px;
  border-top: 850px solid #D7EFFD;
  border-left: 550px solid transparent;
  ${tw`hidden absolute w-0 h-0 -z-1 md:block`}
  
  @media (min-width: 1024px) {
    top: 250px;
    right: -410px;
    border-top-width: 970px;
    border-left-width: 970px;
  }

  @media (min-width: 1921px) {
    right: -730px;
    border-top-width: 1290px;
    border-left-width: 1290px;
  }
`;

const Cafe = styled(Img)`
  top: 140px;
  right: -50px;
  ${tw`hidden absolute! w-120 -z-1 md:block lg:w-200`}
  
  @media (min-width: 1024px) {
    top: 114px;
    right: -220px;
  }
`;

const Triangles = () => {
  const data = useStaticQuery(graphql`
    query {
      concrete: file(relativePath: { eq: "concrete.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      concreteBig: file(relativePath: { eq: "concrete-big.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      cafe: file(relativePath: { eq: "cafe-second.png" }) {
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Concrete fluid={data.concrete.childImageSharp.fluid} />
      <ConcreteBig fluid={data.concreteBig.childImageSharp.fluid} />
      <TriangleBlue />
      <Cafe fluid={data.cafe.childImageSharp.fluid} />
    </>
  );
};

export default Triangles;
