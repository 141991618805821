import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`mb-36 md:w-224 md:mb-0 md:text-left lg:w-300 xl:w-345`;

const Description = styled(Text)`
  ${tw`my-12`}

  b { ${tw`font-600`} }
`;

const ListWrapper = styled.div`
  ${Wrapper}:nth-of-type(1) & {
    ${({ lang }) => (lang === 'en' ? tw`md:mt-24 xl:mt-48` : tw`lg:mt-25 xl:mt-22`)}
  }

  ${Wrapper}:nth-of-type(2) & {
    ${({ lang }) => (lang === 'en' ? tw`md:mt-96 lg:mt-72` : tw`lg:mt-72 xl:mt-20 xl:pt-11`)}
  }
`;

const ListItem = styled.div`
  ${tw`flex my-15 text-left`}
  
  b { ${tw`font-600`} }
`;

const Image = styled(Img)`
  min-width: 18px;
  max-height: 18px;
  ${tw`w-18 mt-4 mr-20`}
`;

const Column = ({ number, list }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      listStyle: file(relativePath: { eq: "cafe-second.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Text fontFamily="bebasNeue" fontSizes={28} fontWeight={700} lineHeight={2.04} letterSpacing={35}>
        <FormattedMessage id={`about etno cafe.how we work.columns.${number}.title`} />
      </Text>
      <Description fontSizes={16} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id={`about etno cafe.how we work.columns.${number}.description`} />
      </Description>
      <ListWrapper lang={intl.locale}>
        {list && list.map((listNumber) => (
          <ListItem key={listNumber}>
            <Image fluid={data.listStyle.childImageSharp.fluid} />
            <Text fontSizes={16} lineHeight={1.5} letterSpacing={20}>
              <FormattedHTMLMessage id={`about etno cafe.how we work.columns.${number}.list.${listNumber}`} />
            </Text>
          </ListItem>
        ))}
      </ListWrapper>
    </Wrapper>
  );
};

Column.propTypes = {
  number: PropTypes.number.isRequired,
  list: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.bool,
  ]).isRequired,
};

export default Column;
