import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Content from '@components/about-etno-cafe/header/content';
import Images from '@components/about-etno-cafe/header/images';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 pt-75 px-15 pb-30 text-center
    sm:pt-90 sm:px-10 sm:pb-40 sm:w-460
    md:w-auto md:pb-120 md:text-left
    lg:pt-212 lg:pb-150
    xl:pb-220
  `}
`;

const Header = () => (
  <Wrapper id="header">
    <Images />
    <Content />
  </Wrapper>
);

export default Header;
