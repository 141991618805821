import React from 'react';
import tw, { styled } from 'twin.macro';

const Mask = styled.div`
  left: -220px;
  right: -220px;
  ${tw`absolute h-450 bg-backgroundBlue -z-1`}
  
  @media (min-width: 1024px) {
    left: -460px;
    right: -460px;
  }
  
  @media (min-width: 1920px) {
    left: -800px;
    right: -800px;
    height: 430px;
  }
`;

const MaskTop = styled(Mask)`
  top: 40px;
  transform: rotate(11deg);
  
  @media (min-width: 640px) {
    top: 60px;
    transform: rotate(8deg);
  }
  
  @media (min-width: 1024px) {
    top: 45px;
  }
`;

const MaskBottom = styled(Mask)`
  bottom: 10px;
  transform: rotate(-2deg);
  
  @media (min-width: 1024px) {
    bottom: 15px;
    transform: rotate(-1deg);
  }
`;

const Masks = () => (
  <>
    <MaskTop />
    <MaskBottom />
  </>
);

export default Masks;
