import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const Coffee = styled(Img)`
  top: 50px;
  left: -100px;
  transform: scaleX(-1);
  ${tw`hidden absolute! w-220 -z-1 md:block lg:w-288`}
  
  @media (min-width: 1024px) {
    left: -150px;
  }

  @media (min-width: 1280px) {
    top: 70px;
    left: -126px;
  }
`;

const Beans = styled(Img)`
  top: 200px;
  right: -50px;
  ${tw`hidden absolute! w-172 -z-1 lg:block xl:right-0`}
`;

const Images = () => {
  const data = useStaticQuery(graphql`
    query {
      coffee: file(relativePath: { eq: "cafe.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      beans: file(relativePath: { eq: "coffee-beans.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Coffee fluid={data.coffee.childImageSharp.fluid} />
      <Beans fluid={data.beans.childImageSharp.fluid} />
    </>
  );
};

export default Images;
