import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`mb-12 text-left`;

const Title = styled(Text)`
  ${tw`relative mb-12`}
  
  ::after {
    content: '';
    ${tw`absolute left-0 bottom-0 inline-block w-18 h-3 bg-primary`}
  }
`;

const Item = ({ number, ...props }) => (
  <Wrapper {...props}>
    <Title fontFamily="bebasNeue" fontSizes={28} fontWeight={700} lineHeight={2.04} letterSpacing={35}>
      <FormattedMessage id={`about etno cafe.our history.list.${number}.title`} />
    </Title>
    <Text fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedMessage id={`about etno cafe.our history.list.${number}.description`} />
    </Text>
  </Wrapper>
);

Item.propTypes = {
  number: PropTypes.number.isRequired,
};

export default Item;
