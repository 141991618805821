import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ShapeDefault from '@images/about/shape.svg';

const Wrapper = tw.div`relative w-320 mx-auto text-center md:mx-0 lg:hidden`;

const ActiveNav = tw.div`relative cursor-pointer`;

const Shape = styled(ShapeDefault)`
  top: -9px;
  ${tw`absolute left-1/2 w-300 transform -translate-x-1/2 -z-1`}
`;

const Triangle = styled.div`
  top: 10px;
  right: 30px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #000;
  transform: scaleY(1);
  ${tw`absolute w-0 h-0 transition-transform duration-400`}

  ${Wrapper}.active & { transform: scaleY(-1); }
`;

const Nav = styled.div`
  top: 40px;
  transition: height .4s, padding-top .4s;
  background-image: linear-gradient(to bottom, #FFF 230px, rgba(255, 255, 255, 0) 100%);
  ${tw`absolute left-0 right-0 h-0 pt-0 px-30 overflow-hidden cursor-pointer z-1`}
  
  ${Wrapper}.active & { ${tw`h-240 pt-20`} }
`;

const NavItem = tw(Text)`py-10 border-b-2 border-pink`;

const MenuMobile = ({ years, activeYear, handleYear }) => {
  const [isOpen, handleOpen] = useState(false);

  const handleNav = (year) => {
    handleYear(year);
    handleOpen(false);
  };

  return (
    <Wrapper className={isOpen ? 'active' : ''}>
      <ActiveNav onClick={() => handleOpen(!isOpen)}>
        <Shape />
        <Text
          fontFamily="bebasNeue"
          fontSizes={30}
          lineHeight={1.2}
          letterSpacing={38}
        >
          <FormattedMessage id={`about etno cafe.our history.story.${activeYear}.year`} />
        </Text>
        <Triangle />
      </ActiveNav>
      <Nav>
        {years.map(({ year }) => (
          <NavItem
            key={year}
            fontFamily="bebasNeue"
            fontSizes={24}
            lineHeight={1.2}
            letterSpacing={38}
            onClick={() => handleNav(year)}
          >
            <FormattedMessage id={`about etno cafe.our history.story.${year}.year`} />
          </NavItem>
        ))}
      </Nav>
    </Wrapper>
  );
};

MenuMobile.propTypes = {
  years: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
    ])),
  ).isRequired,
  activeYear: PropTypes.number.isRequired,
  handleYear: PropTypes.func.isRequired,
};

export default MenuMobile;
