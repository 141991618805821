import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Triangle from '@components/about-etno-cafe/careers/triangle';
import Content from '@components/about-etno-cafe/careers/content';
import CTA from '@components/about-etno-cafe/careers/cta';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative flex flex-col w-320 mb-10 pt-62 px-12 text-center
    sm:w-460
    md:w-auto md:mb-34 md:pt-20 md:text-left
    lg:px-0
  `}
`;

const Careers = () => (
  <Wrapper id="career">
    <Triangle />
    <Content />
    <CTA />
  </Wrapper>
);

export default Careers;
