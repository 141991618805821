import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import MenuMobile from '@components/about-etno-cafe/our-history/years/menu-mobile';
import Year from '@components/about-etno-cafe/our-history/years/year';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import MenuDesktop from '@components/about-etno-cafe/our-history/years/menu-desktop';

const years = [
  { year: 2012, halfActive: [2015] },
  { year: 2015, halfActive: [2012, 2016] },
  { year: 2016, halfActive: [2015, 2021] },
  { year: 2021, halfActive: [2016] },
];

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 mb-40 text-center
    sm:w-460
    md:w-auto md:mb-0 md:px-10 md:text-left
    lg:px-0 lg:pl-95
  `}

  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity .4s;
  }
`;

const Title = styled(Text)`
  ${tw`mb-15 lg:mb-50`}
`;

const Years = () => {
  const [activeYear, handleYear] = useState(2012);

  return (
    <Wrapper>
      <Title
        fontFamily="bebasNeue"
        fontSizes={[42, 54]}
        fontWeight={300}
        lineHeight={[1.48, 1.15]}
        letterSpacing={[52, 67]}
      >
        <FormattedMessage id="about etno cafe.our history.history" />
      </Title>
      <MenuMobile years={years} activeYear={activeYear} handleYear={handleYear} />
      <MenuDesktop years={years} activeYear={activeYear} handleYear={handleYear} />
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={activeYear}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="fade"
        >
          <Year year={activeYear} />
        </CSSTransition>
      </SwitchTransition>
    </Wrapper>
  );
};

export default Years;
