import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '@layouts/';
import SEO from '@components/seo.jsx';
import Header from '@components/about-etno-cafe/header';
import OurHistory from '@components/about-etno-cafe/our-history';
import HowWeWork from '@components/about-etno-cafe/how-we-work';
import Values from '@components/about-etno-cafe/values';
import Careers from '@components/about-etno-cafe/careers';

const navItems = ['header', 'ourHistory', 'howWeWork', 'values', 'career'];

const AboutEtnoCafe = () => {
  const intl = useIntl();

  return (
    <Layout stickyNav={navItems} collection="about etno cafe">
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.about etno cafe' })}
        description={intl.formatMessage({ id: 'meta tags.description.about etno cafe' })}
      />
      <Header />
      <OurHistory />
      <HowWeWork />
      <Values />
      <Careers />
    </Layout>
  );
};

export default AboutEtnoCafe;
