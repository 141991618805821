import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`mt-40 text-left xl:flex xl:flex-wrap xl:justify-between`;

const ItemWrapper = styled.div`
  ${tw`flex mb-15 lg:mb-24`}
  ${({ lang }) => (lang === 'en' ? tw`xl:w-330` : tw`xl:w-340`)}
  
  &:nth-of-type(2), &:nth-of-type(4) { ${tw`xl:w-350`} }

  &:nth-of-type(4) { ${tw`xl:pr-10`} }
`;

const Number = tw(Text)`mr-15 lg:mr-22`;

const Item = styled(Text)`
  ${tw`whitespace-normal md:whitespace-pre-line`}

  b { ${tw`font-600`} }
`;

const List = ({ index, item, lang }) => (
  <Wrapper>
    {item.map((number) => (
      <ItemWrapper key={number} lang={lang}>
        <Number fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
          {number}
          .
        </Number>
        <Item fontSizes={16} lineHeight={1.5} letterSpacing={20}>
          <FormattedHTMLMessage id={`about etno cafe.values.types.${index}.list.${number}`} />
        </Item>
      </ItemWrapper>
    ))}
  </Wrapper>
);

List.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.arrayOf(PropTypes.number).isRequired,
  lang: PropTypes.string.isRequired,
};

export default List;
