import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import tw, { styled, css } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

const years = {
  2012: {
    image: false, yt: true, pl: 'https://www.youtube.com/embed/vTe-rAZJSVk?controls=0', en: 'https://www.youtube.com/embed/PZqwgIFIuUg?controls=0',
  },
  2015: { image: 'okraglak' },
  2016: {
    yt: true, pl: 'https://www.youtube.com/embed/ukBxT9DwD-w?controls=0', en: 'https://www.youtube.com/embed/ukBxT9DwD-w?controls=0',
  },
  2021: { image: 'outsideTheCup' },
};

const Wrapper = tw.div`w-300 h-170 mx-auto md:mx-0 lg:w-540 lg:h-300`;

const Video = tw.div`w-full h-full rounded-2xl overflow-hidden`;

const Iframe = tw.iframe`w-full h-full`;

const Image = styled(Img)`
  ${tw`-z-1`}
  
  ${({ type }) => type === years[2015].image && css`
    top: -108px;
    max-width: 500px;
    
    @media (min-width: 1024px) {
      top: -170px;
      left: 30px;
    }
  `}

  ${({ type }) => type === years[2021].image && css`
    top: -60px;
    left: 50%;
    width: 250px;
    transform: translateX(-50%);
    
    @media (min-width: 1024px) {
      top: -200px;
      width: 520px;
    }
  `}
`;

const Assets = ({ year, ...props }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      okraglak: file(relativePath: { eq: "about/okraglak.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      outsideTheCup: file(relativePath: { eq: "about/outside-the-cup.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper {...props}>
      {years[year].yt ? (
        <Video>
          <Iframe
            width="560"
            height="315"
            src={intl.locale === 'pl' ? years[year].pl : years[year].en}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Video>
      ) : (
        <Image
          type={years[year].image}
          fluid={data[years[year].image].childImageSharp.fluid}
        />
      )}
    </Wrapper>
  );
};

Assets.propTypes = {
  year: PropTypes.number.isRequired,
};

export default Assets;
