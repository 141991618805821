import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { navigationContactUsShort } from '@utils/navigation';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import LinkDefault from '@components/link';

const Link = tw(LinkDefault)`relative inline-block mt-30`;

const Button = tw(ButtonDefault)`mt-0 py-10 px-40`;

const CTA = () => (
  <Link to={navigationContactUsShort.path} isExternal={navigationContactUsShort.externalLink}>
    <Button>
      <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
        <FormattedMessage id={navigationContactUsShort.translation} />
      </Text>
    </Button>
  </Link>
);

export default CTA;
