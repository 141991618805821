import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { customContainer } from '@utils/twin-macro';

import Header from '@components/about-etno-cafe/how-we-work/header';
import Columns from '@components/about-etno-cafe/how-we-work/columns';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 px-10 text-center
    sm:w-420
    md:w-auto md:mb-77 md:pt-68
    lg:px-0
  `}
`;

const Image = styled(Img)`
  top: 55px;
  left: -142px;
  ${tw`absolute! w-200 -z-1`}
  
  @media (min-width: 768px) {
    top: 90px;
    left: -220px;
  }
  
  @media (min-width: 1024px) {
    left: -238px;
  }
`;

const HowWeWork = () => {
  const data = useStaticQuery(graphql`
    query {
      mark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper id="howWeWork">
      <Image fluid={data.mark.childImageSharp.fluid} />
      <Header />
      <Columns />
    </Wrapper>
  );
};

export default HowWeWork;
